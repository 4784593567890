import { makeEnvironmentProviders } from '@angular/core';

import { AdtributeService } from './adtribute.service';
import { ADTRIBUTE_CONFIG, AdtributeConfig } from './adtribute-config';

export function provideAdtributeTracking(config: AdtributeConfig) {
  return makeEnvironmentProviders([
    { provide: ADTRIBUTE_CONFIG, useValue: config },
    AdtributeService,
  ]);
}
