import { Injectable } from '@angular/core';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { SchoolAdminPermissionQuery } from '@memberspot/admin/shared/data-access/school-admin-permission';
import { Customer, isEnterprisePlan } from '@memberspot/models';
import {
  Permission,
  SchoolAdminPermission,
} from '@memberspot/shared/model/permission';
import { generalPermissionChecker } from '@memberspot/shared/util/permission';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { MENU_ITEMS, MENU2ITEMS } from './model/menu-items';
import { Menu } from './model/menu-items.model';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  readonly topMenuItems = this._schoolPermissionsAdminQuery
    .selectActive()
    .pipe(map((per) => this._filterNotAccessibleTopMenuItems(per)));

  readonly bottomMenuItems = combineLatest([
    this._customersQuery.selectLoading(),
    this._schoolPermissionsAdminQuery.selectLoading(),
  ]).pipe(
    filter(
      ([customerLoading, permissionLoading]) =>
        !customerLoading && !permissionLoading,
    ),
    switchMap(() =>
      combineLatest([
        this._customersQuery.selectActive(),
        this._schoolPermissionsAdminQuery.selectActive(),
      ]),
    ),
    map(([customer, adminPermission]) =>
      this._filterNotAccessibleBottomMenuItems(customer, adminPermission),
    ),
  );

  constructor(
    private _customersQuery: CustomersQuery,
    private _schoolPermissionsAdminQuery: SchoolAdminPermissionQuery,
  ) {}

  private _filterNotAccessibleTopMenuItems(per: SchoolAdminPermission) {
    return MENU_ITEMS.map((m) => ({
      ...m,
      children: m.children
        ? m.children.filter((cm) => this._shouldShowItem(cm, per))
        : null,
    })).filter((m) => this._shouldShowItem(m, per));
  }

  private _filterNotAccessibleBottomMenuItems(
    customer: Customer,
    adminPermission: SchoolAdminPermission,
  ) {
    return this._getAvailableMenuItemsForPlan(customer).filter((m) =>
      this._shouldShowItem(m, adminPermission),
    );
  }

  private _shouldShowItem(menu: Menu, adminPermission: SchoolAdminPermission) {
    if (!adminPermission) {
      return false;
    }

    const hasAdminPermission =
      adminPermission.role?.account !== 0 ||
      adminPermission.role?.integrations !== 0 ||
      adminPermission.role?.deleteSchool !== 0;

    if (hasAdminPermission && menu.state === 'settings') {
      return true;
    }

    return (
      menu.alwaysVisible ||
      menu.permissionModule.some((module) =>
        generalPermissionChecker(
          module,
          Permission.READ,
          adminPermission?.role,
        ),
      )
    );
  }

  private _getAvailableMenuItemsForPlan(customer: Customer) {
    const isEnterprise = isEnterprisePlan(customer?.plan);

    return !isEnterprise &&
      !customer?.isChildOf &&
      customer?.status !== 'active'
      ? MENU2ITEMS
      : MENU2ITEMS.filter((mi) => mi.state !== 'settings/billing/pricing');
  }
}
