import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { BillingTopbarComponent } from '@memberspot/admin/shared/service/billing-status';
import { BannerBaseComponent } from '@memberspot/admin/shared/ui/banner';
import { distinctUntilChanged } from 'rxjs';

import { BannerService } from '../banner.service';

@Component({
  selector: 'mspot-banner-alert-handler',
  standalone: true,
  imports: [CommonModule, BannerBaseComponent, BillingTopbarComponent],
  templateUrl: './banner-alert-handler.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'fixed top-0 z-50 block w-full pointer-events-none flex flex-col',
  },
})
export class BannerAlertHandlerComponent {
  private _bannerService = inject(BannerService);
  banners = this._bannerService.getBanners();

  schoolIdChange$ = inject(SchoolsQuery)
    .selectActiveId()
    .pipe(distinctUntilChanged());

  failedPayment = toSignal(inject(CustomersQuery).selectFailedPayment());

  hideBanner = signal(false);

  constructor() {
    this.schoolIdChange$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.hideBanner.set(false));
  }

  hidePaymentBanner() {
    this.hideBanner.set(true);
  }

  onCloseBanner(id: string) {
    this._bannerService.closeBanner(id);
  }
}
