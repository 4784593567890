import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { SyncGuard } from '@memberspot/frontend/shared/data-access/common';
import { Observable } from 'rxjs';

import { AllCoursesAdminService } from '../services/all-courses-admin.service';

@Injectable({
  providedIn: 'root',
})
export class AllCoursesSyncGuard extends SyncGuard {
  constructor(private _allCoursesService: AllCoursesAdminService) {
    super();
  }

  protected sync(params: Params): Observable<any> {
    const { schoolId } = params;

    return this._allCoursesService.sync(schoolId);
  }
}
